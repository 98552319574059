//-------- Clearfix
@mixin clearfix {
  // http://nicolasgallagher.com/micro-clearfix-hack/
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after { clear: both; }
  & { *zoom: 1; }
}

//-------- SEO Text
@mixin seo-text {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

//-------- Animation
@mixin keyframes($animation_name) {
  @keyframes $animation_name {
    @content;
  }
}

//-------- Basic Media Query
@mixin media($point) {
  @media #{$point} {
    @content;
  }
}

//-------- Typography Conversion
// https://github.com/Toadstool-Stipe/stipe/blob/master/stylesheets/stipe/typography/_functions.scss

// General font size function that will convert intigers into em values
// font-size: em(12);
@function em($target, $context: $font_size) {
  @return ($target / $context) * 1em;
}

@function rem($target, $context: $font_size) {
  @return ($target / $context) * 1rem;
}

// Baseline height calculations
// line-height: baseline($baseline_size);
@function baseline($baseline_size) {
  @return ($line / $baseline_size) * ceil($baseline_size / $line) * 1;
}

// Baseline margin calculations
// margin-bottom: baseline-margin($baseline_size);
@function baseline-margin($baseline_size) {
  @return ($line / $baseline_size) * 1em;
}

//-------- Elevation
@mixin elevation($number) {
  @if $number == 0 {
    box-shadow: none;
  } @else if $number == 1 {
    box-shadow: 0 2px 4px -1px rgba(#000000, 0.06);
  } @else if $number == 2 {
    box-shadow: 0 3px 6px 0 rgba(#000000, 0.08);
  } @else if $number == 3 {
    box-shadow: 0 4px 10px 0 rgba(#000000, 0.07);
  } @else if $number == 4 {
    box-shadow: 0 18px 40px 0 rgba(#000000, 0.04);
  }
}


//-------- Wave
@mixin wave {
  @keyframes float-mask {
    0% {
      background-position: 0vw bottom;
    }
    100% {
      background-position: 200vw bottom;
    }
  }

  background-image: url("../images/c-wrapper-mask.svg");
  background-repeat: repeat-x;
  background-position: 0% bottom;
  background-size: 200vw;
  animation: 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) float-mask infinite;
}
