.c-notice {
  // @keyframes bobble {
  //   0% {
  //     border-radius: 2rem 2.80rem;
  //     transform: scaleY(1);
  //   }

  //   15% {
  //     border-radius: 1.80rem 2rem;
  //     transform: scaleY(1.07);
  //   }

  //   70% {
  //     border-radius: 2.80rem 2rem 2rem;
  //     transform: scaleY(0.93);
  //   }

  //   100% {
  //     border-radius: 2rem 2.80rem;
  //     transform: scaleY(1);
  //   }
  // }

  // animation: 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) bobble infinite;
  background: rgba(#ffd852, 0.95);
  color: $c-grey-dark;
  font: 400 12px / 1.3 $ff-primary;
  padding: 1.5vh 1.5vh;
  position: fixed;
  z-index: 99999999;
  right: 10px;
  top: 10px;
  border-radius: 4px;
  text-align: center;

  .c-emoji { display: none; }

  @media #{$bp-desk} {
    font: 400 14px / 1.3 $ff-primary;
  //   .c-emoji { display: inline; }
  //   border-radius: 0 0 0 1rem;
  //   padding: 2vh $s-small 2vh $s-regular;
  //   font: 400 16px / 1.3 $ff-primary;
  //   top: 0;
  //   right: 0;
  //   bottom: auto;
  //   left: auto;
  }
}
