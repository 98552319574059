.c-container {
  @include clearfix;
  box-sizing: border-box;
  margin: auto;
  max-width: $page-max-width;

  // &--small {
  //   900px;
  // }

  &--center { text-align: center; }
}
