.c-paragraph {
  color: #434551;
  font: 400 20px / 1.65 $ff-secondary;
  margin: 0 0 25px;
  max-width: 680px;
  padding: 0;

  @media #{$bp-hand} { font: 400 18px / 1.65 $ff-secondary; }
  @media #{$bp-lap} { font: 400 24px / 1.65 $ff-secondary; }

  br {
    display: none;
    @media #{$bp-lap} { display: inline; }
  }

  &--center {
    text-align: center;
  }

  &--smallest {
    font-size: 12px;
    margin: 0 0 10px;
  }

  &--small {
    font-size: 14px;
    margin: 0 0 10px;
  }

  &--medium {
    color: rgba(#000000, 0.65);
    max-width: 570px;
    font: 400 18px / 1.65 $ff-secondary;
    @media #{$bp-hand} { font: 400 18px / 1.65 $ff-secondary; }
    @media #{$bp-desk} { font: 400 20px / 1.55 $ff-secondary; }
  }

  &--sans-large {
    font-family: $ff-primary;
    color: #727779;
    font-size: 14px;
    @media #{$bp-lap} { font: 400 18px / 1.65 $ff-primary; }
  }

  &--sans-medium {
    font-family: $ff-primary;
    color: #727779;
    font-size: 16px;
    max-width: 570px;
  }

  &--sans-small {
    font-family: $ff-primary;
    font-size: 14px;
    margin: 0 0 10px;
  }

  &--sans-smallest {
    font-family: $ff-primary;
    font-size: 12px;
    margin: 0 0 10px;
  }

  &--quote {
    max-width: none;
    font: 500 18px / 1.9 $ff-secondary;
    font-style: italic;

    &::before {
      content: open-quote;
      font: 500 40px / 0 $ff-secondary;
      margin: 0px 7px 0 -25px;
      opacity: 0.3;
    }
  }

  &--large {
    color: #2c2d35;
    font: 400 28px / 1.4 $ff-display;
    max-width: 980px;
    margin: 0 0 45px;

    @media #{$bp-lap} {
      font: 400 34px / 1.4 $ff-display;
    }
    @media #{$bp-desk} {
      font: 400 44px / 1.4 $ff-display;
    }
  }

  &--xlarge {
    color: #2c2d35;
    font: 400 30px / 1.25 $ff-display;
    max-width: none;
    margin: 0 0 52px;

    @media #{$bp-hand} { font: 400 44px / 1.25 $ff-display; }
    @media #{$bp-lap} { font: 400 60px / 1.25 $ff-display; }
  }

  &--on-dark {
    color: white;
    opacity: 0.6;
  }
}
