.c-navigation {
  $border-height: 4px;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  z-index: 99999;
  opacity: 1;
  animation: 2s ease fade;
  font: 400 14px / 2 $ff-primary;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  color: rgba(#000000, 0.2);
  background: transparent;
  box-sizing: border-box;
  padding: 20px 6vw;

  @media #{$bp-desk} { padding: 34px 8vw; }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // background: var(--theme);
    background: #ffffff;
    transition: background-color 0.25s cubic-bezier(0.35, 0.46, 0.45, 1), height 0.15s cubic-bezier(0.35, 0.46, 0.45, 1);
    opacity: 1;
    z-index: -1;
    height: 0;
    will-change: height, background-position;
    z-index: $z-3;
    transition-delay: 0.5s;

    @media #{$bp-touch} {
      height: 100%;
      box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    // background: linear-gradient(to top, transparent 20%, #ffffff 90%);
    background: linear-gradient(to bottom, var(--theme) 20%, rgba(#ffffff, 0.9) 100%);
    transition: opacity 1s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 1s cubic-bezier(0.35, 0.46, 0.45, 1);
    opacity: 0;
    will-change: opacity;
    visibility: hidden;
    pointer-events: none;
    z-index: $z-2;
    transition-delay: 0.15s;

    @media #{$bp-touch} {
      content: none;
    }
  }

  &.c-navigation--hover {
    &::before {
      transition-delay: unset;
      height: 100%;
    }

    &::after {
      transition-delay: unset;
      transition: opacity 0.2s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 0.2s cubic-bezier(0.35, 0.46, 0.45, 1);
      opacity: 0.9;
      visibility: visible;
    }
  }

  .c-navigation__container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    color: rgba($c-grey-medium, 0.2);
    z-index: $z-4;
    align-items: baseline;
    margin: auto;
    max-width: $page-max-width;
  }

  .c-navigation__breadcrumb-link {
    font: 400 16px / 2 $ff-primary;
    color: $c-grey-medium;
    cursor: w-resize;
    position: relative;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
    display: inline-block;

    &::after {
      background: $c-grey-medium;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transition: all 0.1s ease-in-out;
      width: 100%;
      opacity: 0.1;
    }

    &:hover {
      &::after {
        opacity: 0.3;
        bottom: 3px;
      }
    }

    &--static {
      font: 400 20px / 2 $ff-primary;
      cursor: default;

      &::after { content: none; }
    }
  }
}
