.c-spotify {
  height: 420px;
  position: relative;
  margin-bottom: $s-small;
  border-radius: $border-radius;
  overflow: hidden;
  background: black;
  cursor: pointer;
}

.c-spotify__cover {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  position: absolute;
  transition: opacity 0.2s ease;

  :hover > & {
    opacity: 0.8;
  }

  .c-spotify--expand > & {
    opacity: 0;
  }
}

.c-spotify__content {
  background: rgba(black, 0.7);
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  padding: $s-small $s-small $s-tiny $s-small;
  z-index: +1;
  transition: opacity 0.2s ease;
  backdrop-filter: blur(6px);

  .c-spotify--expand > &:hover {
    background: rgba($c-grey-extradark, 0.7);
  }
}

.c-spotify__iframe {
  box-sizing: border-box;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: +1;
  transition: height 0.3s ease;
  will-change: height;

  .c-spotify--expand > & {
    height: 330px;
  }
}
