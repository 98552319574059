@import "variables";
@import "font-face";
@import "tools";

//---------------------- Base
body {
  background: #ffffff;
  box-sizing: border-box;
  color: $c-grey-medium;
  font: 400 18px / 1.8 $ff-primary;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  font-smoothing: subpixel-antialiased;
}

img {
  box-sizing: border-box;
  display: inherit;
  width: 100%;
}

::selection {
  background: rgba(lighten($c-primary, 30%), 0.7);
  color: $c-primary;
}

[js-breakpoint] {
  display: none;
  position: relative;
  z-index: 0;

  @media #{$bp-lap} { z-index: 1; }
  @media #{$bp-desk} { z-index: 2; }
}

//---------------------- Components
@import "components/c.header";
@import "components/c.browser";
@import "components/c.button";
@import "components/c.container";
@import "components/c.container-2col";
@import "components/c.emoji";
@import "components/c.link";
@import "components/c.list";
@import "components/c.page";
@import "components/c.paragraph";
@import "components/c.title";
@import "components/c.wrapper";
@import "components/c.navigation";
@import "components/c.box";
@import "components/c.citation";
@import "components/c.carousel";
@import "components/c.grid";
@import "components/c.notice";
@import "components/c.loading";
@import "components/c.spotify";

//---------------------- Utilities
@import "utilities/u.spacing";
@import "utilities/u.highlight";
@import "utilities/u.subtle";
@import "utilities/u.color";
@import "utilities/u.animate";
@import "utilities/u.float";
@import "utilities/u.width";
@import "utilities/u.reveal";
@import "utilities/u.border";
@import "utilities/u.hide";

@import "OVERRIDE";
