.c-emoji {
  font-size: 24px;
  vertical-align: middle;
  line-height: initial;

  @media #{$bp-desk} {
    &--in-margin {
      vertical-align: middle;
      margin: 0px 11px 0 -40px;
    }
  }

  &--large {
    font-size: 42px;
  }
}
