$_navigation-width: 16vw;

.c-page {
  background: #ffffff;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-direction: row;
}

.c-page__aside {
  box-sizing: border-box;
  transition: 0.5s width, 0.5s min-width, 0.8s padding;
  will-change: width, min-width;
  position: fixed;
  background: white;
  z-index: 9999;
  min-width: 140px;
  max-width: 160px;
  top: 0;
  width: $_navigation-width;
  background: white;
  position: sticky;
  align-self: flex-start;
  height: 100vh;

  .c-page--collapsed & {
    width: 0;
    min-width: 0;
  }
}

.c-page__view-toggle {
  font: 400 20px / 2 $ff-primary;
  text-transform: uppercase;
  color: #434551;
  padding: 36px $s-xtiny;
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 100000;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out, padding 0.4s ease;
  border-right: 1px solid rgba(#000000, 0.06);

  &:hover {
    background-color: rgba(#000000, 0.04);
  }

  .c-page--collapsed & {
    padding-left: $s-small;
    padding-right: $s-small;
    color: white;
    background-color: #713df0;

    &:hover {
      background-color: #7a47fc;
    }
  }
}

.c-page__aside-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  padding: $s-tiny $s-tiny 0 $s-tiny;
  transition: 0.2s padding-left, 0.2s padding-right;
  will-change: padding-left, padding-right;

  @media #{$bp-lap} {
    padding: $s-small $s-tiny $s-tiny $s-tiny;
  }

  @media #{$bp-desk} {
    padding: $s-regular $s-small $s-tiny $s-large;
  }

  .c-page--collapsed & {
    visibility: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}


.c-page__content {
  @media #{$bp-hand} {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.c-page__footer {
  padding: $s-regular 0;
  background: #f1f9fc;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media #{$bp-lap} {
    height: 100vh;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
}
