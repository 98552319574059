.c-title {
  margin: 0 0 $s-tiny;
  word-wrap: break-word;

  br {
    display: none;
    @media #{$bp-desk} { display: inline; }
  }

  .c-title__subtle {
    font-size: 65%;
    font-weight: 400;
    margin: 0 8px;
    opacity: 0.5;
    vertical-align: baseline;
  }

  .c-title__em {
    font-style: italic;
  }

  &--xlarge {
    font: 400 40px / 1.2 $ff-primary;
    // color: #6a5ef5;
    // color: #508897;

    @media #{$bp-lap} {
      font: 300 76px / 1.2 $ff-display;
    }

    .c-title__subtle {
      font-size: 28px;
    }
  }

  &--large {
    font: 400 22px / 1.4 $ff-primary;
    margin: 0 0 $s-tiny;
    color: #6a5ef5;
    // color: #508897;

    @media #{$bp-lap} {
      font: 400 38px / 1.4 $ff-primary;
      .c-title__subtle { font-size: 50%; }
    }

  }

  &--medium {
    font: 400 24px / 1.4 $ff-display;
    margin: 0 0 $s-small;

    @media #{$bp-lap} {
      font: 400 28px / 1.4 $ff-display;
    }

    @media #{$bp-desk} {
      font: 400 30px / 1.4 $ff-display;
    }
  }

  &--small {
    font: 500 17px / 1.4 $ff-primary;
    letter-spacing: 0;
  }

  &--smallest {
    font: 500 13px / 1.5 $ff-primary;
    letter-spacing: 0;
    opacity: 0.9;

    @media #{$bp-lap} {
      font: 500 13px / 2 $ff-primary;
    }
  }

  &--label {
    display: block;
    font: 400 10px / 1.2 $ff-primary;
    letter-spacing: 1px;
    opacity: .7;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &--flush {
    margin: 0;
    padding: 0;
  }

  &--on-dark {
    color: white;
  }

  &--center {
    text-align: center;
  }
}
