@mixin _about-lockup-1 {
  background-image: url("../images/about-lockup-2019/1.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin _about-lockup-2 {
  background-image: url("../images/about-lockup-2019/2.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}

.OVERRIDE-about-lockup-2 {
  @media #{$bp-desk} {
    @include _about-lockup-2;
    padding: 330px 480px 0 0;
  }

  @media #{$bp-lap-to-desk} {
    @include _about-lockup-2;
    padding-top: 230px;
    padding-right: 50%;
    padding: 25% 50% 0 0;
  }
}


@media #{$bp-desk} {
  .OVERRIDE-about-lockup-1 {
    @include _about-lockup-1;
    padding-bottom: 300px;
  }

  .OVERRIDE-sidebar-bg-1 {
    background-image: url("../images/about-lockup-2019/sidebar-1.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 34%;
  }
}

.OVERRIDE-signature {
  @media #{$bp-hand} {
    background-image: url("../images/signature2.png");
    background-position: 90px 0px;
    background-repeat: no-repeat;
    background-size: 180px;
    padding: 84px 0 40px;
    margin-top: 40px;
  }
}

.OVERRIDE-color-white {
  color: white !important;
  -webkit-font-smoothing: antialiased;
}
