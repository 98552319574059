@charset "UTF-8";
@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Graphik-Light-Web.woff") format("woff"); }

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Graphik-Regular-Web.woff") format("woff"); }

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Graphik-Semibold-Web.woff") format("woff"); }

@font-face {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Graphik-Bold-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Louize-Regular-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Louize-RegularItalic-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Louize-Regular-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Louize-Bold-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Louize-Medium-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Louize-DisplayRegular-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize Display";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Louize-DisplayRegularItalic-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Louize-DisplayMedium-Web.woff") format("woff"); }

@font-face {
  font-family: "Louize Display";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Louize-DisplayBold-Web.woff") format("woff"); }

body {
  background: #ffffff;
  box-sizing: border-box;
  color: #343f48;
  font: 400 18px/1.8 "Graphik", sans-serif;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  font-smoothing: subpixel-antialiased; }

img {
  box-sizing: border-box;
  display: inherit;
  width: 100%; }

::-moz-selection {
  background: rgba(239, 238, 254, 0.7);
  color: #6a5ef5; }

::selection {
  background: rgba(239, 238, 254, 0.7);
  color: #6a5ef5; }

[js-breakpoint] {
  display: none;
  position: relative;
  z-index: 0; }
  @media (min-width: 901px) {
    [js-breakpoint] {
      z-index: 1; } }
  @media (min-width: 1101px) {
    [js-breakpoint] {
      z-index: 2; } }

.c-header {
  margin: 0;
  position: relative; }
  .c-header__background {
    content: "";
    -webkit-transform-origin: top;
            transform-origin: top;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: #f1f9fc -webkit-gradient(linear, left top, left bottom, from(white), color-stop(50%, rgba(255, 255, 255, 0.1)), to(rgba(76, 36, 255, 0.1)));
    background: #f1f9fc -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.1) 50%, rgba(76, 36, 255, 0.1) 100%);
    background: #f1f9fc linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.1) 50%, rgba(76, 36, 255, 0.1) 100%);
    -webkit-transition: 0.5s cubic-bezier(0.35, 0.46, 0.45, 1);
    transition: 0.5s cubic-bezier(0.35, 0.46, 0.45, 1);
    -webkit-transition-property: background-color, height, -webkit-transform;
    transition-property: background-color, height, -webkit-transform;
    transition-property: transform, background-color, height;
    transition-property: transform, background-color, height, -webkit-transform;
    -webkit-animation: 8s cubic-bezier(0.25, 0.46, 0.45, 0.94) float infinite;
            animation: 8s cubic-bezier(0.25, 0.46, 0.45, 0.94) float infinite;
    min-height: 12vh;
    will-change: transform, background-color, height; }

@-webkit-keyframes float {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(0.96);
            transform: scaleY(0.96); }
  75% {
    -webkit-transform: scaleY(1.03);
            transform: scaleY(1.03); }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@keyframes float {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  50% {
    -webkit-transform: scaleY(0.96);
            transform: scaleY(0.96); }
  75% {
    -webkit-transform: scaleY(1.03);
            transform: scaleY(1.03); }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@-webkit-keyframes float-mask {
  0% {
    background-position: 0vw bottom; }
  100% {
    background-position: 200vw bottom; } }

@keyframes float-mask {
  0% {
    background-position: 0vw bottom; }
  100% {
    background-position: 200vw bottom; } }
    .c-header__background::after {
      background-image: url(/static/media/c-wrapper-mask.c0da9c77.svg);
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      background-repeat: repeat-x;
      background-position: 0% bottom;
      height: 25px;
      bottom: -1px;
      background-size: 200vw;
      -webkit-animation: 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) float-mask infinite;
              animation: 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) float-mask infinite;
      will-change: background-position; }
  .c-header__content {
    z-index: +1;
    position: relative;
    box-sizing: border-box;
    padding: 24px 6vw 2vh; }
    @media (min-width: 1101px) {
      .c-header__content {
        padding: 2vh 8vw 120px; } }
    .c-header__content:empty {
      padding: 0;
      min-height: 12vh; }
    .c-header__content > * {
      -webkit-animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in;
              animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in; }

@-webkit-keyframes fade-in {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fade-in {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.c-browser {
  background: #cbd3d8;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  padding: 20px 0 0;
  position: relative;
  box-shadow: 0 4px 18px -4px rgba(0, 0, 0, 0.15);
  max-height: 380px; }
  .c-browser::before {
    background: -webkit-gradient(linear, left top, left bottom, from(#EBF2F6), to(#e3ebf0));
    background: -webkit-linear-gradient(top, #EBF2F6, #e3ebf0);
    background: linear-gradient(to bottom, #EBF2F6, #e3ebf0);
    border-radius: 4px 4px 0 0;
    content: "";
    height: 4px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 20px;
    z-index: +1;
    border: 1px solid #D3E5EF;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5); }
  .c-browser::after {
    border: 1px solid rgba(189, 222, 240, 0.8);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    content: "";
    height: 8px;
    position: absolute;
    top: 6px;
    z-index: +1;
    left: 6px;
    right: 6px;
    margin: 0 auto; }
  .c-browser__content {
    width: 100%;
    box-sizing: border-box; }
  .c-browser--medium {
    height: 400px; }

.c-button {
  border: 1px solid transparent;
  border-radius: 2rem 2.80rem;
  color: #6a5ef5;
  display: inline-block;
  font: 400 16px/1.4 "Graphik", sans-serif;
  padding: 10px 0;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  cursor: pointer; }
  @media (min-width: 901px) {
    .c-button {
      font: 400 18px/1.4 "Graphik", sans-serif; } }
  .c-button:focus, .c-button:active, .c-button:hover {
    border-color: rgba(106, 94, 245, 0.2);
    color: #6a5ef5;
    padding-left: 24px;
    padding-right: 24px;
    -webkit-transition: none;
    transition: none;
    margin-left: -24px;
    background: #ffffff;
    -webkit-animation: 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) bobble infinite;
            animation: 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) bobble infinite; }

@-webkit-keyframes bobble {
  0% {
    border-radius: 2rem 2.80rem;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  15% {
    border-radius: 1.80rem 2rem;
    -webkit-transform: scaleY(1.15);
            transform: scaleY(1.15); }
  70% {
    border-radius: 2.80rem 2rem 2rem;
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95); }
  100% {
    border-radius: 2rem 2.80rem;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }

@keyframes bobble {
  0% {
    border-radius: 2rem 2.80rem;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  15% {
    border-radius: 1.80rem 2rem;
    -webkit-transform: scaleY(1.15);
            transform: scaleY(1.15); }
  70% {
    border-radius: 2.80rem 2rem 2rem;
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95); }
  100% {
    border-radius: 2rem 2.80rem;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); } }
  .c-button:active {
    background: rgba(106, 94, 245, 0.04);
    color: #3e2ef2; }
  .c-button--arrow-remote::before, .c-button--arrow-remote::after, .c-button--arrow-d::before, .c-button--arrow-d::after, .c-button--arrow-r::before, .c-button--arrow-r::after, .c-button--arrow-l::before, .c-button--arrow-l::after {
    opacity: 0.4;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in; }
  .c-button--arrow-remote:active::after, .c-button--arrow-remote:hover::after, .c-button--arrow-remote:active::before, .c-button--arrow-remote:hover::before, .c-button--arrow-d:active::after, .c-button--arrow-d:hover::after, .c-button--arrow-d:active::before, .c-button--arrow-d:hover::before, .c-button--arrow-r:active::after, .c-button--arrow-r:hover::after, .c-button--arrow-r:active::before, .c-button--arrow-r:hover::before, .c-button--arrow-l:active::after, .c-button--arrow-l:hover::after, .c-button--arrow-l:active::before, .c-button--arrow-l:hover::before {
    opacity: 1; }
  .c-button--arrow-d {
    cursor: s-resize; }
    .c-button--arrow-d::after {
      content: '  ↓'; }
  .c-button--arrow-r {
    cursor: e-resize; }
    .c-button--arrow-r::after {
      content: '  \21e2'; }
  .c-button--arrow-l {
    cursor: w-resize; }
    .c-button--arrow-l::before {
      content: '\21e0  '; }
  .c-button--arrow-remote {
    cursor: ne-resize; }
  .c-button--disabled,
  .c-button [disabled] {
    cursor: not-allowed; }
  .c-button--small {
    font: 400 14px/1.4 "Graphik", sans-serif; }
    @media (min-width: 901px) {
      .c-button--small {
        font: 400 16px/1.4 "Graphik", sans-serif; } }
  .c-button--xlarge {
    font: 400 18px/1.4 "Graphik", sans-serif; }
    @media (min-width: 901px) {
      .c-button--xlarge {
        font: 400 22px/2 "Graphik", sans-serif; } }

.c-container {
  box-sizing: border-box;
  margin: auto;
  max-width: 1300px; }
  .c-container:before, .c-container:after {
    content: " ";
    display: table; }
  .c-container:after {
    clear: both; }
  .c-container {
    *zoom: 1; }
  .c-container--center {
    text-align: center; }

.c-container-2col__item {
  padding-top: 54px; }

@media (min-width: 1101px) {
  .c-container-2col {
    margin: auto;
    max-width: 1300px;
    display: flex;
    flex-flow: row;
    list-style: none;
    padding: 0;
    flex-wrap: wrap; }
    .c-container-2col:before, .c-container-2col:after {
      content: " ";
      display: table; }
    .c-container-2col:after {
      clear: both; }
    .c-container-2col {
      *zoom: 1; }
    .c-container-2col .c-container-2col__item {
      flex: 0 1 auto;
      box-sizing: border-box;
      width: 50%;
      display: flex;
      flex-direction: column; }
      .c-container-2col .c-container-2col__item:nth-child(1) {
        padding: 14vh 6vw 0px 0px; }
      .c-container-2col .c-container-2col__item:nth-child(2) {
        border-left: 1px solid #F4F4F4;
        padding: 14vh 0px 0px 6vw; }
      .c-container-2col .c-container-2col__item--stripe:nth-child(1) {
        background: -webkit-gradient(linear, right top, left top, from(#fdf6ff), to(#ffffff));
        background: -webkit-linear-gradient(right, #fdf6ff, #ffffff);
        background: linear-gradient(to left, #fdf6ff, #ffffff); }
      .c-container-2col .c-container-2col__item--stripe:nth-child(2) {
        background: -webkit-gradient(linear, left top, right top, from(#fdf6ff), to(#ffffff));
        background: -webkit-linear-gradient(left, #fdf6ff, #ffffff);
        background: linear-gradient(to right, #fdf6ff, #ffffff); } }

.c-emoji {
  font-size: 24px;
  vertical-align: middle;
  line-height: normal;
  line-height: initial; }
  @media (min-width: 1101px) {
    .c-emoji--in-margin {
      vertical-align: middle;
      margin: 0px 11px 0 -40px; } }
  .c-emoji--large {
    font-size: 42px; }

.c-link {
  color: #343f48;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px; }
  .c-link::before {
    background: rgba(225, 198, 188, 0.4);
    bottom: -4px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    width: 100%; }
  .c-link[href]:hover {
    background: #6a5ef5;
    color: #f8fdff; }
    .c-link[href]:hover::before {
      background: rgba(106, 94, 245, 0.5);
      -webkit-transform: translateY(-3px);
              transform: translateY(-3px); }
  .c-link--arrow-remote {
    cursor: ne-resize; }
  .c-link--arrow-r {
    cursor: e-resize; }
  .c-link--arrow-d {
    cursor: s-resize; }
  .c-link--arrow-d {
    cursor: s-resize; }
  .c-link--active {
    text-decoration: line-through;
    pointer-events: none;
    cursor: not-allowed; }
    .c-link--active::before {
      background: transparent; }
  .c-link--focal {
    font-size: 16px; }
    .c-link--focal::after {
      color: rgba(225, 198, 188, 0.4);
      content: ' \2192';
      margin: 0 0 0 2px;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out; }
    .c-link--focal[href]:hover::after {
      color: #6a5ef5;
      margin: 0 0 0 6px; }
  .c-link--on-dark {
    color: #f8fdff; }
    .c-link--on-dark.c-link--active {
      background: rgba(255, 255, 255, 0.2); }

.c-list {
  list-style: none inside;
  margin: 0 0 20px;
  padding: 0; }
  .c-list .c-list__item {
    font-size: 13px;
    line-height: 1.6;
    margin: 2px 0 10px;
    display: flex;
    align-items: center; }
    .c-list .c-list__item::before {
      content: '-';
      margin: 0 10px 0 -15px; }
    @media (min-width: 1101px) {
      .c-list .c-list__item {
        font-size: 14px;
        margin: 0 0 5px;
        line-height: 1.8; }
        .c-list .c-list__item::before {
          margin: 0 8px 0 0; } }
    .c-list .c-list__item > .c-list {
      margin: 4px 0 4px 34px; }
  .c-list--large > .c-list__item {
    font-size: 14px; }
    @media (min-width: 1101px) {
      .c-list--large > .c-list__item {
        font-size: 16px; } }
  @media (min-width: 1101px) {
    .c-list--horizontal {
      display: flex;
      justify-content: space-between; } }
  .c-list--mushroom .c-list__item::before,
  .c-list .c-list__item--mushroom::before {
    content: "🍄";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--mushroom .c-list__item::before,
      .c-list .c-list__item--mushroom::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--wave .c-list__item::before,
  .c-list .c-list__item--wave::before {
    content: "👋";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--wave .c-list__item::before,
      .c-list .c-list__item--wave::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--rocket .c-list__item::before,
  .c-list .c-list__item--rocket::before {
    content: "🚀";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--rocket .c-list__item::before,
      .c-list .c-list__item--rocket::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--sparkle .c-list__item::before,
  .c-list .c-list__item--sparkle::before {
    content: "✨";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--sparkle .c-list__item::before,
      .c-list .c-list__item--sparkle::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--glove .c-list__item::before,
  .c-list .c-list__item--glove::before {
    content: "🧤";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--glove .c-list__item::before,
      .c-list .c-list__item--glove::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--measure .c-list__item::before,
  .c-list .c-list__item--measure::before {
    content: "📐";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--measure .c-list__item::before,
      .c-list .c-list__item--measure::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--remote .c-list__item::before,
  .c-list .c-list__item--remote::before {
    content: "🔗";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--remote .c-list__item::before,
      .c-list .c-list__item--remote::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--cherry .c-list__item::before,
  .c-list .c-list__item--cherry::before {
    content: "🍒";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--cherry .c-list__item::before,
      .c-list .c-list__item--cherry::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--diamond .c-list__item::before,
  .c-list .c-list__item--diamond::before {
    content: "💎";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--diamond .c-list__item::before,
      .c-list .c-list__item--diamond::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--down .c-list__item::before,
  .c-list .c-list__item--down::before {
    content: "👇";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--down .c-list__item::before,
      .c-list .c-list__item--down::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--computer .c-list__item::before,
  .c-list .c-list__item--computer::before {
    content: "👨‍💻";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--computer .c-list__item::before,
      .c-list .c-list__item--computer::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--exp-1 .c-list__item::before,
  .c-list .c-list__item--exp-1::before {
    content: "🔧";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--exp-1 .c-list__item::before,
      .c-list .c-list__item--exp-1::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--exp-2 .c-list__item::before,
  .c-list .c-list__item--exp-2::before {
    content: "🔨";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--exp-2 .c-list__item::before,
      .c-list .c-list__item--exp-2::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--exp-3 .c-list__item::before,
  .c-list .c-list__item--exp-3::before {
    content: "⚒️";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--exp-3 .c-list__item::before,
      .c-list .c-list__item--exp-3::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }
  .c-list--exp-4 .c-list__item::before,
  .c-list .c-list__item--exp-4::before {
    content: "⚙️";
    margin: 2px 4px auto 2px;
    vertical-align: middle; }
    @media (min-width: 1101px) {
      .c-list--exp-4 .c-list__item::before,
      .c-list .c-list__item--exp-4::before {
        font-size: 135%;
        margin: 2px 9px auto -33px; } }

.c-page {
  background: #ffffff;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-direction: row; }

.c-page__aside {
  box-sizing: border-box;
  -webkit-transition: 0.5s width, 0.5s min-width, 0.8s padding;
  transition: 0.5s width, 0.5s min-width, 0.8s padding;
  will-change: width, min-width;
  position: fixed;
  background: white;
  z-index: 9999;
  min-width: 140px;
  max-width: 160px;
  top: 0;
  width: 16vw;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  height: 100vh; }
  .c-page--collapsed .c-page__aside {
    width: 0;
    min-width: 0; }

.c-page__view-toggle {
  font: 400 20px/2 "Graphik", sans-serif;
  text-transform: uppercase;
  color: #434551;
  padding: 36px 6px;
  box-sizing: border-box;
  background-color: white;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 100000;
  -webkit-transition: background-color 0.1s ease-out, padding 0.4s ease, -webkit-transform 0.1s ease-out;
  transition: background-color 0.1s ease-out, padding 0.4s ease, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out, padding 0.4s ease;
  transition: transform 0.1s ease-out, background-color 0.1s ease-out, padding 0.4s ease, -webkit-transform 0.1s ease-out;
  border-right: 1px solid rgba(0, 0, 0, 0.06); }
  .c-page__view-toggle:hover {
    background-color: rgba(0, 0, 0, 0.04); }
  .c-page--collapsed .c-page__view-toggle {
    padding-left: 24px;
    padding-right: 24px;
    color: white;
    background-color: #713df0; }
    .c-page--collapsed .c-page__view-toggle:hover {
      background-color: #7a47fc; }

.c-page__aside-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  padding: 12px 12px 0 12px;
  -webkit-transition: 0.2s padding-left, 0.2s padding-right;
  transition: 0.2s padding-left, 0.2s padding-right;
  will-change: padding-left, padding-right; }
  @media (min-width: 901px) {
    .c-page__aside-container {
      padding: 24px 12px 12px 12px; } }
  @media (min-width: 1101px) {
    .c-page__aside-container {
      padding: 36px 24px 12px 54px; } }
  .c-page--collapsed .c-page__aside-container {
    visibility: hidden;
    padding-left: 0;
    padding-right: 0; }

@media (min-width: 501px) {
  .c-page__content {
    width: 100%;
    display: flex;
    flex-direction: column; } }

.c-page__footer {
  padding: 36px 0;
  background: #f1f9fc;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  @media (min-width: 901px) {
    .c-page__footer {
      height: 100vh;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1; } }

.c-paragraph {
  color: #434551;
  font: 400 20px/1.65 "Louize", Georgia, "Times New Roman", serif;
  margin: 0 0 25px;
  max-width: 680px;
  padding: 0; }
  @media (min-width: 501px) {
    .c-paragraph {
      font: 400 18px/1.65 "Louize", Georgia, "Times New Roman", serif; } }
  @media (min-width: 901px) {
    .c-paragraph {
      font: 400 24px/1.65 "Louize", Georgia, "Times New Roman", serif; } }
  .c-paragraph br {
    display: none; }
    @media (min-width: 901px) {
      .c-paragraph br {
        display: inline; } }
  .c-paragraph--center {
    text-align: center; }
  .c-paragraph--smallest {
    font-size: 12px;
    margin: 0 0 10px; }
  .c-paragraph--small {
    font-size: 14px;
    margin: 0 0 10px; }
  .c-paragraph--medium {
    color: rgba(0, 0, 0, 0.65);
    max-width: 570px;
    font: 400 18px/1.65 "Louize", Georgia, "Times New Roman", serif; }
    @media (min-width: 501px) {
      .c-paragraph--medium {
        font: 400 18px/1.65 "Louize", Georgia, "Times New Roman", serif; } }
    @media (min-width: 1101px) {
      .c-paragraph--medium {
        font: 400 20px/1.55 "Louize", Georgia, "Times New Roman", serif; } }
  .c-paragraph--sans-large {
    font-family: "Graphik", sans-serif;
    color: #727779;
    font-size: 14px; }
    @media (min-width: 901px) {
      .c-paragraph--sans-large {
        font: 400 18px/1.65 "Graphik", sans-serif; } }
  .c-paragraph--sans-medium {
    font-family: "Graphik", sans-serif;
    color: #727779;
    font-size: 16px;
    max-width: 570px; }
  .c-paragraph--sans-small {
    font-family: "Graphik", sans-serif;
    font-size: 14px;
    margin: 0 0 10px; }
  .c-paragraph--sans-smallest {
    font-family: "Graphik", sans-serif;
    font-size: 12px;
    margin: 0 0 10px; }
  .c-paragraph--quote {
    max-width: none;
    font: 500 18px/1.9 "Louize", Georgia, "Times New Roman", serif;
    font-style: italic; }
    .c-paragraph--quote::before {
      content: open-quote;
      font: 500 40px/0 "Louize", Georgia, "Times New Roman", serif;
      margin: 0px 7px 0 -25px;
      opacity: 0.3; }
  .c-paragraph--large {
    color: #2c2d35;
    font: 400 28px/1.4 "Louize Display", Georgia, "Times New Roman", serif;
    max-width: 980px;
    margin: 0 0 45px; }
    @media (min-width: 901px) {
      .c-paragraph--large {
        font: 400 34px/1.4 "Louize Display", Georgia, "Times New Roman", serif; } }
    @media (min-width: 1101px) {
      .c-paragraph--large {
        font: 400 44px/1.4 "Louize Display", Georgia, "Times New Roman", serif; } }
  .c-paragraph--xlarge {
    color: #2c2d35;
    font: 400 30px/1.25 "Louize Display", Georgia, "Times New Roman", serif;
    max-width: none;
    margin: 0 0 52px; }
    @media (min-width: 501px) {
      .c-paragraph--xlarge {
        font: 400 44px/1.25 "Louize Display", Georgia, "Times New Roman", serif; } }
    @media (min-width: 901px) {
      .c-paragraph--xlarge {
        font: 400 60px/1.25 "Louize Display", Georgia, "Times New Roman", serif; } }
  .c-paragraph--on-dark {
    color: white;
    opacity: 0.6; }

.c-title {
  margin: 0 0 12px;
  word-wrap: break-word; }
  .c-title br {
    display: none; }
    @media (min-width: 1101px) {
      .c-title br {
        display: inline; } }
  .c-title .c-title__subtle {
    font-size: 65%;
    font-weight: 400;
    margin: 0 8px;
    opacity: 0.5;
    vertical-align: baseline; }
  .c-title .c-title__em {
    font-style: italic; }
  .c-title--xlarge {
    font: 400 40px/1.2 "Graphik", sans-serif; }
    @media (min-width: 901px) {
      .c-title--xlarge {
        font: 300 76px/1.2 "Louize Display", Georgia, "Times New Roman", serif; } }
    .c-title--xlarge .c-title__subtle {
      font-size: 28px; }
  .c-title--large {
    font: 400 22px/1.4 "Graphik", sans-serif;
    margin: 0 0 12px;
    color: #6a5ef5; }
    @media (min-width: 901px) {
      .c-title--large {
        font: 400 38px/1.4 "Graphik", sans-serif; }
        .c-title--large .c-title__subtle {
          font-size: 50%; } }
  .c-title--medium {
    font: 400 24px/1.4 "Louize Display", Georgia, "Times New Roman", serif;
    margin: 0 0 24px; }
    @media (min-width: 901px) {
      .c-title--medium {
        font: 400 28px/1.4 "Louize Display", Georgia, "Times New Roman", serif; } }
    @media (min-width: 1101px) {
      .c-title--medium {
        font: 400 30px/1.4 "Louize Display", Georgia, "Times New Roman", serif; } }
  .c-title--small {
    font: 500 17px/1.4 "Graphik", sans-serif;
    letter-spacing: 0; }
  .c-title--smallest {
    font: 500 13px/1.5 "Graphik", sans-serif;
    letter-spacing: 0;
    opacity: 0.9; }
    @media (min-width: 901px) {
      .c-title--smallest {
        font: 500 13px/2 "Graphik", sans-serif; } }
  .c-title--label {
    display: block;
    font: 400 10px/1.2 "Graphik", sans-serif;
    letter-spacing: 1px;
    opacity: .7;
    text-transform: uppercase;
    white-space: nowrap; }
  .c-title--flush {
    margin: 0;
    padding: 0; }
  .c-title--on-dark {
    color: white; }
  .c-title--center {
    text-align: center; }

.c-wrapper {
  margin: 0;
  padding: 60px 6vw;
  position: relative;
  box-sizing: border-box; }
  @media (min-width: 901px) {
    .c-wrapper {
      padding: 80px 6vw; } }
  @media (min-width: 1101px) {
    .c-wrapper {
      padding: 140px 8vw; } }
  .c-wrapper--header {
    padding-top: 120px; }
    @media (min-width: 901px) {
      .c-wrapper--header {
        padding-bottom: 130px;
        padding-top: 160px; } }
  .c-wrapper--full {
    overflow-x: hidden;
    overflow-y: visible; }
    @media (min-width: 1101px) {
      .c-wrapper--full {
        padding-left: 9%;
        padding-right: 9%; } }
  .c-wrapper--footer {
    bottom: 0;
    left: 0;
    padding: 0 100px;
    position: fixed;
    right: 0;
    z-index: -1; }
  .c-wrapper--slim {
    padding-bottom: 50px;
    padding-top: 50px; }
  .c-wrapper--slimmest {
    padding-bottom: 15px;
    padding-top: 15px; }
  .c-wrapper--flush {
    padding-bottom: 0;
    padding-top: 0; }
  .c-wrapper--white {
    background: #ffffff; }
  .c-wrapper--light-blue {
    background: #f1f9fc -webkit-gradient(linear, left top, left bottom, from(#f9fbf9), to(#f1f9fc));
    background: #f1f9fc -webkit-linear-gradient(top, #f9fbf9 0%, #f1f9fc 100%);
    background: #f1f9fc linear-gradient(to bottom, #f9fbf9 0%, #f1f9fc 100%); }
  .c-wrapper--light-purple {
    background: #faf6ff -webkit-gradient(linear, left top, left bottom, from(#fffeff), to(#f3fbfd));
    background: #faf6ff -webkit-linear-gradient(top, #fffeff 0%, #f3fbfd 100%);
    background: #faf6ff linear-gradient(to bottom, #fffeff 0%, #f3fbfd 100%); }
  .c-wrapper--light {
    background: #fff9f6; }
  .c-wrapper--masked::after {
    background: transparent url("/assets/images/c-wrapper-mask.svg") no-repeat center center 100% 100%;
    content: '';
    position: absolute;
    display: block;
    left: -1%;
    right: -1%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    height: 25px;
    bottom: -1px;
    background-size: cover; }
  .c-wrapper__video-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.2;
    width: 100%;
    margin: -25% 0 0 0; }

.c-navigation {
  z-index: 99999;
  opacity: 1;
  -webkit-animation: 2s ease fade;
          animation: 2s ease fade;
  font: 400 14px/2 "Graphik", sans-serif;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  color: rgba(0, 0, 0, 0.2);
  background: transparent;
  box-sizing: border-box;
  padding: 20px 6vw; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  @media (min-width: 1101px) {
    .c-navigation {
      padding: 34px 8vw; } }
  .c-navigation::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffff;
    -webkit-transition: background-color 0.25s cubic-bezier(0.35, 0.46, 0.45, 1), height 0.15s cubic-bezier(0.35, 0.46, 0.45, 1);
    transition: background-color 0.25s cubic-bezier(0.35, 0.46, 0.45, 1), height 0.15s cubic-bezier(0.35, 0.46, 0.45, 1);
    opacity: 1;
    z-index: -1;
    height: 0;
    will-change: height, background-position;
    z-index: 30;
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s; }
    @media (hover: none) and (pointer: coarse) {
      .c-navigation::before {
        height: 100%;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05); } }
  .c-navigation::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, var(--theme)), to(rgba(255, 255, 255, 0.9)));
    background: -webkit-linear-gradient(top, var(--theme) 20%, rgba(255, 255, 255, 0.9) 100%);
    background: linear-gradient(to bottom, var(--theme) 20%, rgba(255, 255, 255, 0.9) 100%);
    -webkit-transition: opacity 1s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 1s cubic-bezier(0.35, 0.46, 0.45, 1);
    transition: opacity 1s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 1s cubic-bezier(0.35, 0.46, 0.45, 1);
    opacity: 0;
    will-change: opacity;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;
    -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s; }
    @media (hover: none) and (pointer: coarse) {
      .c-navigation::after {
        content: none; } }
  .c-navigation.c-navigation--hover::before {
    -webkit-transition-delay: unset;
            transition-delay: unset;
    height: 100%; }
  .c-navigation.c-navigation--hover::after {
    -webkit-transition-delay: unset;
            transition-delay: unset;
    -webkit-transition: opacity 0.2s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 0.2s cubic-bezier(0.35, 0.46, 0.45, 1);
    transition: opacity 0.2s cubic-bezier(0.35, 0.46, 0.45, 1), visibility 0.2s cubic-bezier(0.35, 0.46, 0.45, 1);
    opacity: 0.9;
    visibility: visible; }
  .c-navigation .c-navigation__container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    color: rgba(52, 63, 72, 0.2);
    z-index: 40;
    align-items: baseline;
    margin: auto;
    max-width: 1300px; }
  .c-navigation .c-navigation__breadcrumb-link {
    font: 400 16px/2 "Graphik", sans-serif;
    color: #343f48;
    cursor: w-resize;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
    display: inline-block; }
    .c-navigation .c-navigation__breadcrumb-link::after {
      background: #343f48;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
      width: 100%;
      opacity: 0.1; }
    .c-navigation .c-navigation__breadcrumb-link:hover::after {
      opacity: 0.3;
      bottom: 3px; }
    .c-navigation .c-navigation__breadcrumb-link--static {
      font: 400 20px/2 "Graphik", sans-serif;
      cursor: default; }
      .c-navigation .c-navigation__breadcrumb-link--static::after {
        content: none; }

.c-box {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin: 0 0 20px;
  padding: 35px; }
  .c-box:before, .c-box:after {
    content: " ";
    display: table; }
  .c-box:after {
    clear: both; }
  .c-box {
    *zoom: 1; }
  @media (min-width: 901px) {
    .c-box {
      padding: 50px 60px; } }

.c-citation:before, .c-citation:after {
  content: " ";
  display: table; }

.c-citation:after {
  clear: both; }

.c-citation {
  *zoom: 1; }

.c-citation--photo {
  border-radius: 5px;
  float: left;
  height: 34px;
  margin: 10px 10px 0 0;
  overflow: hidden;
  width: 34px; }

.c-citation--author {
  color: #434551;
  margin: 10px 0 0;
  font: 400 12px/34px "Graphik", sans-serif; }

.c-carousel__item {
  margin-left: 5%;
  width: 70vw;
  border-radius: 4px;
  box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  will-change: opacity, transform; }
  @media (min-width: 901px) {
    .c-carousel__item {
      margin-left: 4%;
      width: 70vw; } }
  .c-carousel__item.is-selected {
    -webkit-transform: scale(1);
            transform: scale(1); }

@media (min-width: 501px) {
  .c-carousel .flickity-viewport {
    overflow: visible; } }

.c-carousel .flickity-page-dots {
  bottom: -45px; }
  .c-carousel .flickity-page-dots .dot {
    height: 4px;
    width: 4px;
    margin: 0 8px;
    opacity: 0.1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #000000; }
    .c-carousel .flickity-page-dots .dot.is-selected {
      -webkit-transform: scale(1.8);
              transform: scale(1.8);
      opacity: 0.3; }

.c-carousel--small .c-carousel__item {
  box-shadow: none;
  border-radius: 0;
  max-width: 40vw;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.c-carousel--on-dark .flickity-page-dots .dot {
  background: #ffffff; }

/* ==========================================================================
   #GRID –
   Modified inuitcss flexbox grid: https://www.npmjs.com/package/inuitcss-flexbox
   ========================================================================== */
/**
 *
 * Cells are full-width and will stack on top of each other by default:
 *
 * <div class="c-grid">
 *   <div class="c-grid__cell">
 *   </div>
 *   <div class="c-grid__cell">
 *   </div>
 * </div>
 *
 * Cells will in most cases be accompanied by utility classes that divide
 * the grid into fractions. These are provided by inuitcss:
 *
 * <div class="c-grid">
 *   <div class="c-grid__cell u-1/2">
 *   </div>
 *   <div class="c-grid__cell u-1/2">
 *   </div>
 * </div>
 *
 * Several modifier classes are provided. For example, `c-grid--auto` will
 * divide the space equally between all containing cells without the need
 * for width utility classes.
 *
 * <div class="c-grid--auto">
 *   <div class="c-grid__cell">
 *   </div>
 *   <div class="c-grid__cell">
 *   </div>
 * </div>
 */
/* The grid
   ========================================================================== */
.c-grid {
  display: flex;
  flex-flow: row;
  list-style: none;
  padding: 0;
  flex-wrap: wrap; }

/**
 * 1. Cells are full-width and stack on top of each other by default.
 */
.c-grid__cell {
  flex: 0 1 auto;
  box-sizing: border-box;
  width: 100%;
  /* [1] */ }

/* Gutters
   ========================================================================== */
/**
 * 1. The map $inuit-flexgrid-spacing-sizes contains a `null` key which
 *    will be our default gutter size.
 * 2. Add a negative margin on one side only. That way we aren't required to use
 *    a wrapper with the same amount of spacing, though it's common to do so.
 * 3. Neutralize the left margin on `c-grid`.
 */
.c-grid {
  /* [1] */
  margin-left: -2.8vw;
  /* [2] */ }
  .c-grid > .c-grid__cell {
    padding-left: 2.8vw;
    /* [3] */ }

.c-grid--regular {
  /* [1] */
  margin-left: -36px;
  /* [2] */ }
  .c-grid--regular > .c-grid__cell {
    padding-left: 36px;
    /* [3] */ }

.c-grid--tiny {
  /* [1] */
  margin-left: -12px;
  /* [2] */ }
  .c-grid--tiny > .c-grid__cell {
    padding-left: 12px;
    /* [3] */ }

.c-grid--small {
  /* [1] */
  margin-left: -24px;
  /* [2] */ }
  .c-grid--small > .c-grid__cell {
    padding-left: 24px;
    /* [3] */ }

.c-grid--large {
  /* [1] */
  margin-left: -54px;
  /* [2] */ }
  .c-grid--large > .c-grid__cell {
    padding-left: 54px;
    /* [3] */ }

.c-grid--flush {
  /* [1] */
  margin-left: 0;
  /* [2] */ }
  .c-grid--flush > .c-grid__cell {
    padding-left: 0;
    /* [3] */ }

/* Automatically size cells by distributing them equally
   ========================================================================== */
.c-grid--auto {
  flex-wrap: nowrap; }
  .c-grid--auto > .c-grid__cell {
    flex: 0 0 auto;
    width: auto; }

.c-grid--stretch {
  flex-wrap: nowrap; }
  .c-grid--stretch > .c-grid__cell {
    flex: 1 0 auto;
    width: auto; }

/* Horizontal alignment
   ========================================================================== */
.c-grid--left {
  justify-content: flex-start; }

.c-grid--center {
  justify-content: center; }

.c-grid--right {
  justify-content: flex-end; }

.c-grid--between {
  justify-content: space-between; }

.c-grid--around {
  justify-content: space-around; }

/* Pull behavior
   ========================================================================== */
.c-grid__cell--pull-left {
  margin-right: auto; }

.c-grid__cell--pull-right {
  margin-left: auto; }

/* Vertical alignment
   ========================================================================== */
.c-grid--top {
  align-items: flex-start; }

.c-grid--middle {
  align-items: center; }

.c-grid--bottom {
  align-items: flex-end; }

.c-grid--baseline {
  align-items: baseline; }

.c-grid--stretch {
  align-items: stretch; }

.c-grid__cell--top {
  align-self: flex-start; }

.c-grid__cell--middle {
  align-self: center; }

.c-grid__cell--bottom {
  align-self: flex-end; }

.c-grid__cell--baseline {
  align-self: baseline; }

.c-grid__cell--stretch {
  align-self: stretch; }

/* Content distribution
   ========================================================================== */
.c-grid--reverse {
  flex-direction: row-reverse; }

.c-grid--column {
  flex-direction: column; }

.c-grid--column-reverse {
  flex-direction: column-reverse; }

.c-notice {
  background: rgba(255, 216, 82, 0.95);
  color: #252A2D;
  font: 400 12px/1.3 "Graphik", sans-serif;
  padding: 1.5vh 1.5vh;
  position: fixed;
  z-index: 99999999;
  right: 10px;
  top: 10px;
  border-radius: 4px;
  text-align: center; }
  .c-notice .c-emoji {
    display: none; }
  @media (min-width: 1101px) {
    .c-notice {
      font: 400 14px/1.3 "Graphik", sans-serif; } }

.c-loading {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  width: 80%;
  position: fixed;
  height: 4px;
  background: red;
  -webkit-animation: 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) slide-in;
          animation: 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) slide-in;
  -webkit-transition: all 2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

@-webkit-keyframes slide-in {
  0% {
    width: 0; }
  10% {
    width: 40%; }
  100% {
    width: 80%; } }

@keyframes slide-in {
  0% {
    width: 0; }
  10% {
    width: 40%; }
  100% {
    width: 80%; } }
  .c-loading--complete {
    width: 100% !important;
    -webkit-animation: none !important;
            animation: none !important; }

.c-spotify {
  height: 420px;
  position: relative;
  margin-bottom: 24px;
  border-radius: 5px;
  overflow: hidden;
  background: black;
  cursor: pointer; }

.c-spotify__cover {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  position: absolute;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease; }
  :hover > .c-spotify__cover {
    opacity: 0.8; }
  .c-spotify--expand > .c-spotify__cover {
    opacity: 0; }

.c-spotify__content {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  padding: 24px 24px 12px 24px;
  z-index: +1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px); }
  .c-spotify--expand > .c-spotify__content:hover {
    background: rgba(34, 36, 44, 0.7); }

.c-spotify__iframe {
  box-sizing: border-box;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: +1;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
  will-change: height; }
  .c-spotify--expand > .c-spotify__iframe {
    height: 330px; }

.u-padding-auto {
  padding: auto !important; }

.u-padding-xtiny {
  padding: 6px !important; }

.u-padding-tiny {
  padding: 12px !important; }

.u-padding-small {
  padding: 24px !important; }

.u-padding {
  padding: 36px !important; }

.u-padding-large {
  padding: 54px !important; }

.u-padding-none {
  padding: 0 !important; }

.u-padding-top-auto {
  padding-top: auto !important; }

.u-padding-top-xtiny {
  padding-top: 6px !important; }

.u-padding-top-tiny {
  padding-top: 12px !important; }

.u-padding-top-small {
  padding-top: 24px !important; }

.u-padding-top {
  padding-top: 36px !important; }

.u-padding-top-large {
  padding-top: 54px !important; }

.u-padding-top-none {
  padding-top: 0 !important; }

.u-padding-right-auto {
  padding-right: auto !important; }

.u-padding-right-xtiny {
  padding-right: 6px !important; }

.u-padding-right-tiny {
  padding-right: 12px !important; }

.u-padding-right-small {
  padding-right: 24px !important; }

.u-padding-right {
  padding-right: 36px !important; }

.u-padding-right-large {
  padding-right: 54px !important; }

.u-padding-right-none {
  padding-right: 0 !important; }

.u-padding-bottom-auto {
  padding-bottom: auto !important; }

.u-padding-bottom-xtiny {
  padding-bottom: 6px !important; }

.u-padding-bottom-tiny {
  padding-bottom: 12px !important; }

.u-padding-bottom-small {
  padding-bottom: 24px !important; }

.u-padding-bottom {
  padding-bottom: 36px !important; }

.u-padding-bottom-large {
  padding-bottom: 54px !important; }

.u-padding-bottom-none {
  padding-bottom: 0 !important; }

.u-padding-left-auto {
  padding-left: auto !important; }

.u-padding-left-xtiny {
  padding-left: 6px !important; }

.u-padding-left-tiny {
  padding-left: 12px !important; }

.u-padding-left-small {
  padding-left: 24px !important; }

.u-padding-left {
  padding-left: 36px !important; }

.u-padding-left-large {
  padding-left: 54px !important; }

.u-padding-left-none {
  padding-left: 0 !important; }

.u-padding-horizontal-auto {
  padding-left: auto !important;
  padding-right: auto !important; }

.u-padding-horizontal-xtiny {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.u-padding-horizontal-tiny {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.u-padding-horizontal-small {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.u-padding-horizontal {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.u-padding-horizontal-large {
  padding-left: 54px !important;
  padding-right: 54px !important; }

.u-padding-horizontal-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.u-padding-vertical-auto {
  padding-top: auto !important;
  padding-bottom: auto !important; }

.u-padding-vertical-xtiny {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }

.u-padding-vertical-tiny {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.u-padding-vertical-small {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.u-padding-vertical {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.u-padding-vertical-large {
  padding-top: 54px !important;
  padding-bottom: 54px !important; }

.u-padding-vertical-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.u-margin-auto {
  margin: auto !important; }

.u-margin-xtiny {
  margin: 6px !important; }

.u-margin-tiny {
  margin: 12px !important; }

.u-margin-small {
  margin: 24px !important; }

.u-margin {
  margin: 36px !important; }

.u-margin-large {
  margin: 54px !important; }

.u-margin-none {
  margin: 0 !important; }

.u-margin-top-auto {
  margin-top: auto !important; }

.u-margin-top-xtiny {
  margin-top: 6px !important; }

.u-margin-top-tiny {
  margin-top: 12px !important; }

.u-margin-top-small {
  margin-top: 24px !important; }

.u-margin-top {
  margin-top: 36px !important; }

.u-margin-top-large {
  margin-top: 54px !important; }

.u-margin-top-none {
  margin-top: 0 !important; }

.u-margin-right-auto {
  margin-right: auto !important; }

.u-margin-right-xtiny {
  margin-right: 6px !important; }

.u-margin-right-tiny {
  margin-right: 12px !important; }

.u-margin-right-small {
  margin-right: 24px !important; }

.u-margin-right {
  margin-right: 36px !important; }

.u-margin-right-large {
  margin-right: 54px !important; }

.u-margin-right-none {
  margin-right: 0 !important; }

.u-margin-bottom-auto {
  margin-bottom: auto !important; }

.u-margin-bottom-xtiny {
  margin-bottom: 6px !important; }

.u-margin-bottom-tiny {
  margin-bottom: 12px !important; }

.u-margin-bottom-small {
  margin-bottom: 24px !important; }

.u-margin-bottom {
  margin-bottom: 36px !important; }

.u-margin-bottom-large {
  margin-bottom: 54px !important; }

.u-margin-bottom-none {
  margin-bottom: 0 !important; }

.u-margin-left-auto {
  margin-left: auto !important; }

.u-margin-left-xtiny {
  margin-left: 6px !important; }

.u-margin-left-tiny {
  margin-left: 12px !important; }

.u-margin-left-small {
  margin-left: 24px !important; }

.u-margin-left {
  margin-left: 36px !important; }

.u-margin-left-large {
  margin-left: 54px !important; }

.u-margin-left-none {
  margin-left: 0 !important; }

.u-margin-horizontal-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-margin-horizontal-xtiny {
  margin-left: 6px !important;
  margin-right: 6px !important; }

.u-margin-horizontal-tiny {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.u-margin-horizontal-small {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.u-margin-horizontal {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.u-margin-horizontal-large {
  margin-left: 54px !important;
  margin-right: 54px !important; }

.u-margin-horizontal-none {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.u-margin-vertical-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.u-margin-vertical-xtiny {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }

.u-margin-vertical-tiny {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.u-margin-vertical-small {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.u-margin-vertical {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.u-margin-vertical-large {
  margin-top: 54px !important;
  margin-bottom: 54px !important; }

.u-margin-vertical-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.u-margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto; }

.u-highlight {
  background-image: -webkit-linear-gradient(190deg, rgba(250, 247, 133, 0.3), rgba(250, 247, 133, 0.6) 95%, rgba(250, 247, 133, 0.1));
  background-image: linear-gradient(-100deg, rgba(250, 247, 133, 0.3), rgba(250, 247, 133, 0.6) 95%, rgba(250, 247, 133, 0.1));
  color: #292a31; }

.u-subtler {
  opacity: 0.25; }

.u-subtle {
  opacity: 0.6; }

.u-color-primary {
  color: #6a5ef5; }

.u-animate-in {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in;
          animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in; }

@keyframes fade-in {
  0% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    opacity: 0; }
  70% {
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.u-float-right {
  float: right !important; }

.u-float-left {
  float: left !important; }

/* ==========================================================================
   #WIDTHS
   ========================================================================== */
/**
 * inuitcss generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-1/2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="c-grid__cell  u-1/2">
 *
 * By default, inuitcss will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="c-grid__cell  u-1/1  u-1/2--tablet  u-1/3--desktop">
 *
 */
.u-1\/1 {
  width: 100% !important; }

.u-1\/2 {
  width: 50% !important; }

.u-2\/2 {
  width: 100% !important; }

.u-1\/3 {
  width: 33.33333% !important; }

.u-2\/3 {
  width: 66.66667% !important; }

.u-3\/3 {
  width: 100% !important; }

.u-1\/4 {
  width: 25% !important; }

.u-2\/4 {
  width: 50% !important; }

.u-3\/4 {
  width: 75% !important; }

.u-4\/4 {
  width: 100% !important; }

.u-1\/5 {
  width: 20% !important; }

.u-2\/5 {
  width: 40% !important; }

.u-3\/5 {
  width: 60% !important; }

.u-4\/5 {
  width: 80% !important; }

.u-5\/5 {
  width: 100% !important; }

.u-1\/6 {
  width: 16.66667% !important; }

.u-2\/6 {
  width: 33.33333% !important; }

.u-3\/6 {
  width: 50% !important; }

.u-4\/6 {
  width: 66.66667% !important; }

.u-5\/6 {
  width: 83.33333% !important; }

.u-6\/6 {
  width: 100% !important; }

.u-1\/7 {
  width: 14.28571% !important; }

.u-2\/7 {
  width: 28.57143% !important; }

.u-3\/7 {
  width: 42.85714% !important; }

.u-4\/7 {
  width: 57.14286% !important; }

.u-5\/7 {
  width: 71.42857% !important; }

.u-6\/7 {
  width: 85.71429% !important; }

.u-7\/7 {
  width: 100% !important; }

.u-1\/8 {
  width: 12.5% !important; }

.u-2\/8 {
  width: 25% !important; }

.u-3\/8 {
  width: 37.5% !important; }

.u-4\/8 {
  width: 50% !important; }

.u-5\/8 {
  width: 62.5% !important; }

.u-6\/8 {
  width: 75% !important; }

.u-7\/8 {
  width: 87.5% !important; }

.u-8\/8 {
  width: 100% !important; }

.u-1\/9 {
  width: 11.11111% !important; }

.u-2\/9 {
  width: 22.22222% !important; }

.u-3\/9 {
  width: 33.33333% !important; }

.u-4\/9 {
  width: 44.44444% !important; }

.u-5\/9 {
  width: 55.55556% !important; }

.u-6\/9 {
  width: 66.66667% !important; }

.u-7\/9 {
  width: 77.77778% !important; }

.u-8\/9 {
  width: 88.88889% !important; }

.u-9\/9 {
  width: 100% !important; }

.u-1\/10 {
  width: 10% !important; }

.u-2\/10 {
  width: 20% !important; }

.u-3\/10 {
  width: 30% !important; }

.u-4\/10 {
  width: 40% !important; }

.u-5\/10 {
  width: 50% !important; }

.u-6\/10 {
  width: 60% !important; }

.u-7\/10 {
  width: 70% !important; }

.u-8\/10 {
  width: 80% !important; }

.u-9\/10 {
  width: 90% !important; }

.u-10\/10 {
  width: 100% !important; }

.u-1\/11 {
  width: 9.09091% !important; }

.u-2\/11 {
  width: 18.18182% !important; }

.u-3\/11 {
  width: 27.27273% !important; }

.u-4\/11 {
  width: 36.36364% !important; }

.u-5\/11 {
  width: 45.45455% !important; }

.u-6\/11 {
  width: 54.54545% !important; }

.u-7\/11 {
  width: 63.63636% !important; }

.u-8\/11 {
  width: 72.72727% !important; }

.u-9\/11 {
  width: 81.81818% !important; }

.u-10\/11 {
  width: 90.90909% !important; }

.u-11\/11 {
  width: 100% !important; }

.u-1\/12 {
  width: 8.33333% !important; }

.u-2\/12 {
  width: 16.66667% !important; }

.u-3\/12 {
  width: 25% !important; }

.u-4\/12 {
  width: 33.33333% !important; }

.u-5\/12 {
  width: 41.66667% !important; }

.u-6\/12 {
  width: 50% !important; }

.u-7\/12 {
  width: 58.33333% !important; }

.u-8\/12 {
  width: 66.66667% !important; }

.u-9\/12 {
  width: 75% !important; }

.u-10\/12 {
  width: 83.33333% !important; }

.u-11\/12 {
  width: 91.66667% !important; }

.u-12\/12 {
  width: 100% !important; }

.u-1\/13 {
  width: 7.69231% !important; }

.u-2\/13 {
  width: 15.38462% !important; }

.u-3\/13 {
  width: 23.07692% !important; }

.u-4\/13 {
  width: 30.76923% !important; }

.u-5\/13 {
  width: 38.46154% !important; }

.u-6\/13 {
  width: 46.15385% !important; }

.u-7\/13 {
  width: 53.84615% !important; }

.u-8\/13 {
  width: 61.53846% !important; }

.u-9\/13 {
  width: 69.23077% !important; }

.u-10\/13 {
  width: 76.92308% !important; }

.u-11\/13 {
  width: 84.61538% !important; }

.u-12\/13 {
  width: 92.30769% !important; }

.u-13\/13 {
  width: 100% !important; }

.u-1\/14 {
  width: 7.14286% !important; }

.u-2\/14 {
  width: 14.28571% !important; }

.u-3\/14 {
  width: 21.42857% !important; }

.u-4\/14 {
  width: 28.57143% !important; }

.u-5\/14 {
  width: 35.71429% !important; }

.u-6\/14 {
  width: 42.85714% !important; }

.u-7\/14 {
  width: 50% !important; }

.u-8\/14 {
  width: 57.14286% !important; }

.u-9\/14 {
  width: 64.28571% !important; }

.u-10\/14 {
  width: 71.42857% !important; }

.u-11\/14 {
  width: 78.57143% !important; }

.u-12\/14 {
  width: 85.71429% !important; }

.u-13\/14 {
  width: 92.85714% !important; }

.u-14\/14 {
  width: 100% !important; }

.u-1\/15 {
  width: 6.66667% !important; }

.u-2\/15 {
  width: 13.33333% !important; }

.u-3\/15 {
  width: 20% !important; }

.u-4\/15 {
  width: 26.66667% !important; }

.u-5\/15 {
  width: 33.33333% !important; }

.u-6\/15 {
  width: 40% !important; }

.u-7\/15 {
  width: 46.66667% !important; }

.u-8\/15 {
  width: 53.33333% !important; }

.u-9\/15 {
  width: 60% !important; }

.u-10\/15 {
  width: 66.66667% !important; }

.u-11\/15 {
  width: 73.33333% !important; }

.u-12\/15 {
  width: 80% !important; }

.u-13\/15 {
  width: 86.66667% !important; }

.u-14\/15 {
  width: 93.33333% !important; }

.u-15\/15 {
  width: 100% !important; }

@media (min-width: 901px) {
  .u-1\/1--lap {
    width: 100% !important; }
  .u-1\/2--lap {
    width: 50% !important; }
  .u-2\/2--lap {
    width: 100% !important; }
  .u-1\/3--lap {
    width: 33.33333% !important; }
  .u-2\/3--lap {
    width: 66.66667% !important; }
  .u-3\/3--lap {
    width: 100% !important; }
  .u-1\/4--lap {
    width: 25% !important; }
  .u-2\/4--lap {
    width: 50% !important; }
  .u-3\/4--lap {
    width: 75% !important; }
  .u-4\/4--lap {
    width: 100% !important; }
  .u-1\/5--lap {
    width: 20% !important; }
  .u-2\/5--lap {
    width: 40% !important; }
  .u-3\/5--lap {
    width: 60% !important; }
  .u-4\/5--lap {
    width: 80% !important; }
  .u-5\/5--lap {
    width: 100% !important; }
  .u-1\/6--lap {
    width: 16.66667% !important; }
  .u-2\/6--lap {
    width: 33.33333% !important; }
  .u-3\/6--lap {
    width: 50% !important; }
  .u-4\/6--lap {
    width: 66.66667% !important; }
  .u-5\/6--lap {
    width: 83.33333% !important; }
  .u-6\/6--lap {
    width: 100% !important; }
  .u-1\/7--lap {
    width: 14.28571% !important; }
  .u-2\/7--lap {
    width: 28.57143% !important; }
  .u-3\/7--lap {
    width: 42.85714% !important; }
  .u-4\/7--lap {
    width: 57.14286% !important; }
  .u-5\/7--lap {
    width: 71.42857% !important; }
  .u-6\/7--lap {
    width: 85.71429% !important; }
  .u-7\/7--lap {
    width: 100% !important; }
  .u-1\/8--lap {
    width: 12.5% !important; }
  .u-2\/8--lap {
    width: 25% !important; }
  .u-3\/8--lap {
    width: 37.5% !important; }
  .u-4\/8--lap {
    width: 50% !important; }
  .u-5\/8--lap {
    width: 62.5% !important; }
  .u-6\/8--lap {
    width: 75% !important; }
  .u-7\/8--lap {
    width: 87.5% !important; }
  .u-8\/8--lap {
    width: 100% !important; }
  .u-1\/9--lap {
    width: 11.11111% !important; }
  .u-2\/9--lap {
    width: 22.22222% !important; }
  .u-3\/9--lap {
    width: 33.33333% !important; }
  .u-4\/9--lap {
    width: 44.44444% !important; }
  .u-5\/9--lap {
    width: 55.55556% !important; }
  .u-6\/9--lap {
    width: 66.66667% !important; }
  .u-7\/9--lap {
    width: 77.77778% !important; }
  .u-8\/9--lap {
    width: 88.88889% !important; }
  .u-9\/9--lap {
    width: 100% !important; }
  .u-1\/10--lap {
    width: 10% !important; }
  .u-2\/10--lap {
    width: 20% !important; }
  .u-3\/10--lap {
    width: 30% !important; }
  .u-4\/10--lap {
    width: 40% !important; }
  .u-5\/10--lap {
    width: 50% !important; }
  .u-6\/10--lap {
    width: 60% !important; }
  .u-7\/10--lap {
    width: 70% !important; }
  .u-8\/10--lap {
    width: 80% !important; }
  .u-9\/10--lap {
    width: 90% !important; }
  .u-10\/10--lap {
    width: 100% !important; }
  .u-1\/11--lap {
    width: 9.09091% !important; }
  .u-2\/11--lap {
    width: 18.18182% !important; }
  .u-3\/11--lap {
    width: 27.27273% !important; }
  .u-4\/11--lap {
    width: 36.36364% !important; }
  .u-5\/11--lap {
    width: 45.45455% !important; }
  .u-6\/11--lap {
    width: 54.54545% !important; }
  .u-7\/11--lap {
    width: 63.63636% !important; }
  .u-8\/11--lap {
    width: 72.72727% !important; }
  .u-9\/11--lap {
    width: 81.81818% !important; }
  .u-10\/11--lap {
    width: 90.90909% !important; }
  .u-11\/11--lap {
    width: 100% !important; }
  .u-1\/12--lap {
    width: 8.33333% !important; }
  .u-2\/12--lap {
    width: 16.66667% !important; }
  .u-3\/12--lap {
    width: 25% !important; }
  .u-4\/12--lap {
    width: 33.33333% !important; }
  .u-5\/12--lap {
    width: 41.66667% !important; }
  .u-6\/12--lap {
    width: 50% !important; }
  .u-7\/12--lap {
    width: 58.33333% !important; }
  .u-8\/12--lap {
    width: 66.66667% !important; }
  .u-9\/12--lap {
    width: 75% !important; }
  .u-10\/12--lap {
    width: 83.33333% !important; }
  .u-11\/12--lap {
    width: 91.66667% !important; }
  .u-12\/12--lap {
    width: 100% !important; }
  .u-1\/13--lap {
    width: 7.69231% !important; }
  .u-2\/13--lap {
    width: 15.38462% !important; }
  .u-3\/13--lap {
    width: 23.07692% !important; }
  .u-4\/13--lap {
    width: 30.76923% !important; }
  .u-5\/13--lap {
    width: 38.46154% !important; }
  .u-6\/13--lap {
    width: 46.15385% !important; }
  .u-7\/13--lap {
    width: 53.84615% !important; }
  .u-8\/13--lap {
    width: 61.53846% !important; }
  .u-9\/13--lap {
    width: 69.23077% !important; }
  .u-10\/13--lap {
    width: 76.92308% !important; }
  .u-11\/13--lap {
    width: 84.61538% !important; }
  .u-12\/13--lap {
    width: 92.30769% !important; }
  .u-13\/13--lap {
    width: 100% !important; }
  .u-1\/14--lap {
    width: 7.14286% !important; }
  .u-2\/14--lap {
    width: 14.28571% !important; }
  .u-3\/14--lap {
    width: 21.42857% !important; }
  .u-4\/14--lap {
    width: 28.57143% !important; }
  .u-5\/14--lap {
    width: 35.71429% !important; }
  .u-6\/14--lap {
    width: 42.85714% !important; }
  .u-7\/14--lap {
    width: 50% !important; }
  .u-8\/14--lap {
    width: 57.14286% !important; }
  .u-9\/14--lap {
    width: 64.28571% !important; }
  .u-10\/14--lap {
    width: 71.42857% !important; }
  .u-11\/14--lap {
    width: 78.57143% !important; }
  .u-12\/14--lap {
    width: 85.71429% !important; }
  .u-13\/14--lap {
    width: 92.85714% !important; }
  .u-14\/14--lap {
    width: 100% !important; }
  .u-1\/15--lap {
    width: 6.66667% !important; }
  .u-2\/15--lap {
    width: 13.33333% !important; }
  .u-3\/15--lap {
    width: 20% !important; }
  .u-4\/15--lap {
    width: 26.66667% !important; }
  .u-5\/15--lap {
    width: 33.33333% !important; }
  .u-6\/15--lap {
    width: 40% !important; }
  .u-7\/15--lap {
    width: 46.66667% !important; }
  .u-8\/15--lap {
    width: 53.33333% !important; }
  .u-9\/15--lap {
    width: 60% !important; }
  .u-10\/15--lap {
    width: 66.66667% !important; }
  .u-11\/15--lap {
    width: 73.33333% !important; }
  .u-12\/15--lap {
    width: 80% !important; }
  .u-13\/15--lap {
    width: 86.66667% !important; }
  .u-14\/15--lap {
    width: 93.33333% !important; }
  .u-15\/15--lap {
    width: 100% !important; } }

@media (min-width: 1101px) {
  .u-1\/1--desk {
    width: 100% !important; }
  .u-1\/2--desk {
    width: 50% !important; }
  .u-2\/2--desk {
    width: 100% !important; }
  .u-1\/3--desk {
    width: 33.33333% !important; }
  .u-2\/3--desk {
    width: 66.66667% !important; }
  .u-3\/3--desk {
    width: 100% !important; }
  .u-1\/4--desk {
    width: 25% !important; }
  .u-2\/4--desk {
    width: 50% !important; }
  .u-3\/4--desk {
    width: 75% !important; }
  .u-4\/4--desk {
    width: 100% !important; }
  .u-1\/5--desk {
    width: 20% !important; }
  .u-2\/5--desk {
    width: 40% !important; }
  .u-3\/5--desk {
    width: 60% !important; }
  .u-4\/5--desk {
    width: 80% !important; }
  .u-5\/5--desk {
    width: 100% !important; }
  .u-1\/6--desk {
    width: 16.66667% !important; }
  .u-2\/6--desk {
    width: 33.33333% !important; }
  .u-3\/6--desk {
    width: 50% !important; }
  .u-4\/6--desk {
    width: 66.66667% !important; }
  .u-5\/6--desk {
    width: 83.33333% !important; }
  .u-6\/6--desk {
    width: 100% !important; }
  .u-1\/7--desk {
    width: 14.28571% !important; }
  .u-2\/7--desk {
    width: 28.57143% !important; }
  .u-3\/7--desk {
    width: 42.85714% !important; }
  .u-4\/7--desk {
    width: 57.14286% !important; }
  .u-5\/7--desk {
    width: 71.42857% !important; }
  .u-6\/7--desk {
    width: 85.71429% !important; }
  .u-7\/7--desk {
    width: 100% !important; }
  .u-1\/8--desk {
    width: 12.5% !important; }
  .u-2\/8--desk {
    width: 25% !important; }
  .u-3\/8--desk {
    width: 37.5% !important; }
  .u-4\/8--desk {
    width: 50% !important; }
  .u-5\/8--desk {
    width: 62.5% !important; }
  .u-6\/8--desk {
    width: 75% !important; }
  .u-7\/8--desk {
    width: 87.5% !important; }
  .u-8\/8--desk {
    width: 100% !important; }
  .u-1\/9--desk {
    width: 11.11111% !important; }
  .u-2\/9--desk {
    width: 22.22222% !important; }
  .u-3\/9--desk {
    width: 33.33333% !important; }
  .u-4\/9--desk {
    width: 44.44444% !important; }
  .u-5\/9--desk {
    width: 55.55556% !important; }
  .u-6\/9--desk {
    width: 66.66667% !important; }
  .u-7\/9--desk {
    width: 77.77778% !important; }
  .u-8\/9--desk {
    width: 88.88889% !important; }
  .u-9\/9--desk {
    width: 100% !important; }
  .u-1\/10--desk {
    width: 10% !important; }
  .u-2\/10--desk {
    width: 20% !important; }
  .u-3\/10--desk {
    width: 30% !important; }
  .u-4\/10--desk {
    width: 40% !important; }
  .u-5\/10--desk {
    width: 50% !important; }
  .u-6\/10--desk {
    width: 60% !important; }
  .u-7\/10--desk {
    width: 70% !important; }
  .u-8\/10--desk {
    width: 80% !important; }
  .u-9\/10--desk {
    width: 90% !important; }
  .u-10\/10--desk {
    width: 100% !important; }
  .u-1\/11--desk {
    width: 9.09091% !important; }
  .u-2\/11--desk {
    width: 18.18182% !important; }
  .u-3\/11--desk {
    width: 27.27273% !important; }
  .u-4\/11--desk {
    width: 36.36364% !important; }
  .u-5\/11--desk {
    width: 45.45455% !important; }
  .u-6\/11--desk {
    width: 54.54545% !important; }
  .u-7\/11--desk {
    width: 63.63636% !important; }
  .u-8\/11--desk {
    width: 72.72727% !important; }
  .u-9\/11--desk {
    width: 81.81818% !important; }
  .u-10\/11--desk {
    width: 90.90909% !important; }
  .u-11\/11--desk {
    width: 100% !important; }
  .u-1\/12--desk {
    width: 8.33333% !important; }
  .u-2\/12--desk {
    width: 16.66667% !important; }
  .u-3\/12--desk {
    width: 25% !important; }
  .u-4\/12--desk {
    width: 33.33333% !important; }
  .u-5\/12--desk {
    width: 41.66667% !important; }
  .u-6\/12--desk {
    width: 50% !important; }
  .u-7\/12--desk {
    width: 58.33333% !important; }
  .u-8\/12--desk {
    width: 66.66667% !important; }
  .u-9\/12--desk {
    width: 75% !important; }
  .u-10\/12--desk {
    width: 83.33333% !important; }
  .u-11\/12--desk {
    width: 91.66667% !important; }
  .u-12\/12--desk {
    width: 100% !important; }
  .u-1\/13--desk {
    width: 7.69231% !important; }
  .u-2\/13--desk {
    width: 15.38462% !important; }
  .u-3\/13--desk {
    width: 23.07692% !important; }
  .u-4\/13--desk {
    width: 30.76923% !important; }
  .u-5\/13--desk {
    width: 38.46154% !important; }
  .u-6\/13--desk {
    width: 46.15385% !important; }
  .u-7\/13--desk {
    width: 53.84615% !important; }
  .u-8\/13--desk {
    width: 61.53846% !important; }
  .u-9\/13--desk {
    width: 69.23077% !important; }
  .u-10\/13--desk {
    width: 76.92308% !important; }
  .u-11\/13--desk {
    width: 84.61538% !important; }
  .u-12\/13--desk {
    width: 92.30769% !important; }
  .u-13\/13--desk {
    width: 100% !important; }
  .u-1\/14--desk {
    width: 7.14286% !important; }
  .u-2\/14--desk {
    width: 14.28571% !important; }
  .u-3\/14--desk {
    width: 21.42857% !important; }
  .u-4\/14--desk {
    width: 28.57143% !important; }
  .u-5\/14--desk {
    width: 35.71429% !important; }
  .u-6\/14--desk {
    width: 42.85714% !important; }
  .u-7\/14--desk {
    width: 50% !important; }
  .u-8\/14--desk {
    width: 57.14286% !important; }
  .u-9\/14--desk {
    width: 64.28571% !important; }
  .u-10\/14--desk {
    width: 71.42857% !important; }
  .u-11\/14--desk {
    width: 78.57143% !important; }
  .u-12\/14--desk {
    width: 85.71429% !important; }
  .u-13\/14--desk {
    width: 92.85714% !important; }
  .u-14\/14--desk {
    width: 100% !important; }
  .u-1\/15--desk {
    width: 6.66667% !important; }
  .u-2\/15--desk {
    width: 13.33333% !important; }
  .u-3\/15--desk {
    width: 20% !important; }
  .u-4\/15--desk {
    width: 26.66667% !important; }
  .u-5\/15--desk {
    width: 33.33333% !important; }
  .u-6\/15--desk {
    width: 40% !important; }
  .u-7\/15--desk {
    width: 46.66667% !important; }
  .u-8\/15--desk {
    width: 53.33333% !important; }
  .u-9\/15--desk {
    width: 60% !important; }
  .u-10\/15--desk {
    width: 66.66667% !important; }
  .u-11\/15--desk {
    width: 73.33333% !important; }
  .u-12\/15--desk {
    width: 80% !important; }
  .u-13\/15--desk {
    width: 86.66667% !important; }
  .u-14\/15--desk {
    width: 93.33333% !important; }
  .u-15\/15--desk {
    width: 100% !important; } }

.u-reveal {
  opacity: 0.1;
  -webkit-transition: opacity ease 0.8s;
  transition: opacity ease 0.8s; }
  .u-reveal--active {
    opacity: 1; }

.u-border-dark-horizontal {
  border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important; }

.u-border-dark-left {
  border-left: 1px solid rgba(0, 0, 0, 0.06) !important; }

.u-border-dark-right {
  border-right: 1px solid rgba(0, 0, 0, 0.06) !important; }

.u-border-dark-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important; }

.u-border-dark-top {
  border-top: 1px solid rgba(0, 0, 0, 0.06) !important; }

.u-border {
  border: 1px solid #F4F4F4 !important; }
  .u-border-horizontal {
    border-top: 1px solid #F4F4F4 !important;
    border-bottom: 1px solid #F4F4F4 !important; }
  .u-border-left {
    border-left: 1px solid #F4F4F4 !important; }
  .u-border-right {
    border-right: 1px solid #F4F4F4 !important; }
  .u-border-bottom {
    border-bottom: 1px solid #F4F4F4 !important; }
  .u-border-top {
    border-top: 1px solid #F4F4F4 !important; }
  .u-border-none {
    border: none !important; }
  .u-border-left-none {
    border-left: none !important; }
  .u-border-right-none {
    border-right: none !important; }
  .u-border-bottom-none {
    border-bottom: none !important; }
  .u-border-top-none {
    border-top: none !important; }

.u-hide {
  display: none; }
  @media (max-width: 501px) {
    .u-hide--hand {
      display: none; } }
  @media (max-width: 901px) {
    .u-hide--lap {
      display: none; } }
  @media (max-width: 1101px) {
    .u-hide--desk {
      display: none; } }

@media (min-width: 1101px) {
  .OVERRIDE-about-lockup-2 {
    background-image: url(/static/media/2.8bf02468.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 330px 480px 0 0; } }

@media (min-width: 901px) and (max-width: 1101px) {
  .OVERRIDE-about-lockup-2 {
    background-image: url(/static/media/2.8bf02468.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 230px;
    padding-right: 50%;
    padding: 25% 50% 0 0; } }

@media (min-width: 1101px) {
  .OVERRIDE-about-lockup-1 {
    background-image: url(/static/media/1.040fb954.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 300px; }
  .OVERRIDE-sidebar-bg-1 {
    background-image: url(/static/media/sidebar-1.c0d4acba.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 34%; } }

@media (min-width: 501px) {
  .OVERRIDE-signature {
    background-image: url(/static/media/signature2.a3c24eb0.png);
    background-position: 90px 0px;
    background-repeat: no-repeat;
    background-size: 180px;
    padding: 84px 0 40px;
    margin-top: 40px; } }

.OVERRIDE-color-white {
  color: white !important;
  -webkit-font-smoothing: antialiased; }

