.c-link {
  $faded-color: rgba($c-tertiary, 0.4);

  // transition: all 0.1s ease-in-out;
  color: $c-grey-medium;
  position: relative;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: $s-xtiny;
  padding-right: $s-xtiny;
  border-radius: $border-radius;

  &::before {
    background: $faded-color;
    bottom: -4px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.1s ease-in-out;
    width: 100%;
  }

  &[href]:hover {
    background: $c-primary;
    color: $c-grey-extralight;

    &::before {
      background: rgba($c-primary, 0.5);
      transform: translateY(-3px);
    }
  }

  &--arrow-remote {
    cursor: ne-resize;
  }

  &--arrow-r {
    cursor: e-resize;
  }

  &--arrow-d {
    cursor: s-resize;
  }

  &--arrow-d {
    cursor: s-resize;
  }

  &--active {
    text-decoration: line-through;
    pointer-events: none;
    cursor: not-allowed;

    &::before { background: transparent; }
  }

  &--focal {
    font-size: 16px;

    &::after {
      color: $faded-color;
      content: ' \2192';
      margin: 0 0 0 2px;
      transition: all 0.1s ease-in-out;
    }

    &[href]:hover::after {
      color: $c-primary;
      margin: 0 0 0 6px;
    }
  }

  &--on-dark {
    color: $c-grey-extralight;

    &.c-link--active {
      background: rgba(#ffffff, 0.2);
    }
  }
}
