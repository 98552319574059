.u-animate-in {
  @keyframes fade-in {
    0% {
      transform: translateY(8px);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) fade-in;
}
